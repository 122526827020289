function generateBoundingBoxes(
  box,
  image,
  tolerance = 0.25,
  margin = 0.1,
  count = 1,
  pad_per = 0.2
) {
  let thresh = parseFloat((Math.random() * tolerance).toFixed(4));
  let ops = Math.round(Math.random());
  let n_h = image.height;
  let n_w = image.width;

  let iter = 0;

  while (n_h >= image.height - 20 || n_w >= image.width - 20) {
    iter += 1;
    if (iter === 5) {
      n_w = box.width;
      n_h = box.height;
      break;
    }
    thresh = parseFloat((Math.random() * tolerance).toFixed(4));
    ops = Math.round(Math.random());
    if (ops === 1) {
      n_h = Math.floor(box.height + box.height * thresh);
      n_w = Math.floor(box.width + box.width * thresh);

      if (
        n_h + image.height * pad_per >= image.height - image.height * pad_per ||
        n_w + image.width * pad_per >= image.width - image.width * pad_per
      ) {
        ops = 0;
      }
    }
    if (ops === 0) {
      n_h = Math.floor(box.height - box.height * thresh);
      n_w = Math.floor(box.width - box.width * thresh);
    }
  }

  let min_x = n_w / 2 + image.width * pad_per;
  let max_x = image.width - n_w / 2 - image.width * pad_per;
  let min_y = n_h / 2 + image.height * pad_per;
  let max_y = image.height - n_h / 2 - image.height * pad_per;

  if (n_w > image.width - image.width * pad_per || n_h > image.height - image.height * pad_per) {
    min_x = n_w / 2;
    max_x = image.width - n_w / 2;
    min_y = n_h / 2;
    max_y = image.height - n_h / 2;
  }

  const n_x = min_x + Math.floor(Math.random() * (max_x - min_x));
  const n_y = min_y + Math.floor(Math.random() * (max_y - min_y));

  const generatedBox = {};
  generatedBox.x = n_x;
  generatedBox.y = n_y;
  generatedBox.width = n_w;
  generatedBox.height = n_h;
  generatedBox.unit = "px";

  return generatedBox;
}

export default generateBoundingBoxes;
