import React, { useEffect, useState } from "react";
import { Typography, Container, CardMedia, Card, Box, IconButton } from "@mui/material";
import Button from "components/Button";

import XaneLogo from "assets/image/XaneLogo.png";
import { getIdentity } from "config/apiHandler";

function Features({ features }) {
  const [identity, setIdentity] = useState("");
  useEffect(() => {
    getIdentity().then((id) => {
      setIdentity(id);
    });
  }, []);
  return (
    <Box
      sx={{
        width: "inherit",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "0px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "4px",
            position: "relative",
            height: "100px",
            width: "70%",
            overflow: "hidden",
            marginTop: "16px",
          }}
        >
          <CardMedia
            component="img"
            image={XaneLogo}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "100%",
              width: "auto",
            }}
          />
        </Box>
        <Typography
          variant="h6"
          color="#D3D3D3"
          sx={{
            textTransform: "capitalize",
            textShadow: `rgb(211, 211, 211,0.4) 3px 3px 3px `,
          }}
        >
          {process.env.REACT_APP_PROJECT_NAME.replace(/-/g, " ")}
        </Typography>
        <Typography variant="caption" color="#D3D3D3">
          {identity}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          width: "100%",
          overflow: "scroll",
          padding: "16px",
          maxHeight: "70%",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {features.map((feature) => (
          <Box
            key={feature.id}
            sx={{
              backgroundColor: "#162936",
              boxShadow: `9px 9px 14px 0 rgba(0, 0, 0, 0.3),-3px -3px 10px 0 rgba(255, 255, 255, 0.3)`,
              borderRadius: "8px",
              height: "auto",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  height: "50px",
                  width: "70px",
                  borderRadius: "8px",
                  backgroundColor: "#162936",
                  boxShadow: `-12px -12px 16px 0 rgba(0, 0, 0, 0.25),4px 4px 12px 0 rgba(255, 255, 255, 0.3)`,
                }}
              >
                <CardMedia
                  sx={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  image={feature.image_url}
                  title={feature.code}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h6" color="#D3D3D3">
                  {feature.code.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                </Typography>
                <IconButton
                  onClick={() => {
                    window.location.href = `/capture/feature/${feature.id}/${feature.code}`;
                  }}
                >
                  <Button />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Features;
